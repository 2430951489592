// assets/js/app.js

require('bootstrap');
require('../css/app.scss');
require('./core.js');
require('../lib/lity/lity.min.js');
require('../lib/owl/owl.carousel.min.js');
require('../lib/magnific/jquery.magnific-popup.min.js');



const $ = require('jquery');



//create global $ and jQuery variables
global.$ = global.jQuery = $;



